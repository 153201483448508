<template>
  <c-flex
    position="relative"
    width="100%"
    height="100%"
    flex-direction="column"
    margin-inline="auto"
    :background-color="['#F2F2F2', '#FFF']"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :padding="['1rem 1rem 80px 1rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', 'auto']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumbLabel"
      />
    </Portal>

    <c-box v-if="isLoadingData">
      <c-flex
        width="100%"
        flex-direction="column"
        justify-content="center"
        align-items="center"
        padding="128px 24px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
          margin-bottom="16px"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Rencana Makan...
        </c-text>
      </c-flex>
    </c-box>

    <EmptyState 
      v-else-if="!isHaveMealPlanMonths"
      title="Kamu belum memiliki program aktif"
      description="Yuk beli program agar mendapatkan panduan makan yang sesuai untukmu."
      button-text="Beli Program"
      button-route="client.select-program"
    />
    <EmptyState
      v-else-if="!data?.mealPlanSchedules?.length"
      title="Rencana makanmu masih kosong"
      description="Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!"
      button-text="Beranda"
      button-route="client.index"
    />
    <c-box
      v-else
      width="100%"
      :background-color="['#FFF', 'transparent']"
      :padding="['16px', '0px']"
    >
      <c-flex
        gap="2rem"
        :margin-bottom="['0.5rem','1rem']"
      >
        <c-button
          variant="ghost"
          padding="0px"
          width="30px"
          height="30px"
          min-width="30x"
          :display="['none', 'flex']"
          align-items="center"
          @click="$router.back()"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-chevron-left.svg')"
            height="20px"
            width="20px"
          />
        </c-button>
        <c-box
          width="100%"
        >
          <DownloadMealPlan
            v-if="data?.mealPlanSchedules?.length >= 1"
            :program-id-local="activeProgram?.id"
            :month="$route.params.month"
          />

          <c-text
            :display="['none', 'block']"
            color="neutral.black"
            font-size="20px"
            font-weight="700"
            text-align="center"
          >
            Rencana Makan
          </c-text>
        </c-box>
      </c-flex>

      <c-grid
        :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
        :gap="['16px', '20px']"
        margin-bottom="24px"
      >
        <c-flex
          v-for="(item, i) in data?.mealPlanSchedules"
          :key="i"
          width="100%"
          align-items="center"
          background-color="#FFF"
          :padding="['16px', '20px']"
          border-radius="8px"
          gap="8px"
          :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
          :cursor="item?.isSkipped ? 'not-allowed' : 'pointer'"
          :opacity="item?.isSkipped ? '0.7' : '1'"
          @click="onClickItem(item)"
        >
          <c-flex
            flex-grow="1"
            justify-content="space-between"
            align-items="center"
          >
            <c-box

              margin-right="1rem"
            >
              <c-image
                :width="['40px', '60px']"
                :height="['40px', '60px']"
                :src="constants.mealTileIdToImageUrl[item?.mealTime]"
              />
            </c-box>
            <c-flex
              flex-direction="column"
              margin-right="auto"
            >
              <c-text
                color="neutral.black"
                :font-size="['14px', '18px']"
                font-weight="500"
              >
                {{ constants.mealTileIdToLabel[item?.mealTime] || '-' }}
              </c-text>
              <c-text
                color="neutral.black"
                :font-size="['12px', '16px']"
                font-weight="400"
              >
                {{ item?.caloriesAmount ? Math.round(Number(item?.caloriesAmount)) + ' kkal' : '-' }}
              </c-text>
            </c-flex>
            <c-box
              v-if="item?.isSkipped"
              flex-shrink="0"
              width="20px"
              height="20px"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-lock-green.svg')"
                stroke="#008C81"
              />
            </c-box>
            <c-box
              v-else
              :font-size="['12px', '16px']"
              font-weight="400"
            >
              {{ format24Time(item?.startTime) }} - {{ format24Time(item?.endTime) }}
            </c-box>
          </c-flex>
          <c-box
            flex-shrink="0"
            width="20px"
            height="20px"
          >
            <inline-svg
              :src="require('@/assets/icon-chevron-right.svg')"
              stroke="#000"
            />
          </c-box>
        </c-flex>
      </c-grid>
      <c-box>
        <c-flex
          gap="16px"
          :flex-direction="['column', 'row']"
        >
          <CardInfoThingsThatNeed
            color="warning"
            label="Dibatasi"
          >
            <template #items>
              <c-box
                :font-size="['14px', '16px']"
                v-html="data?.mealRecommendations?.limited"
              />
            </template>
          </CardInfoThingsThatNeed>
          <CardInfoThingsThatNeed
            color="danger"
            label="Dilarang"
          >
            <template #items>
              <c-box
                :font-size="['14px', '16px']"
                v-html="data?.mealRecommendations?.avoided"
              />
            </template>
          </CardInfoThingsThatNeed>
        </c-flex>
      </c-box>
    </c-box>

    <BaseModal
      :is-open="isOpenPopup"
      :close-on-overlay-click="false"
      :with-button-close="false"
      has-footer
      size="800px"
    >
      <template #header>
        <c-flex
          width="100%"
          justify-content="center"
          align-items="center"
          padding="16px 20px"
          position="relative"
        >
          <c-text
            :font-size="['14px', '18px']"
            font-weight="500"
            color="neutral.black"
          >
            Yay, panduan makanmu sudah ada
          </c-text>
          <c-flex
            align-items="center"
            gap="12px"
            position="absolute"
            top="10px"
            right="10px"
          >
            <c-button
              variant="ghost"
              :width="['20px','30px']"
              :min-width="['20px','30px']"
              height="30px"
              margin="0"
              padding="0"
              @click="handleClosePopupGreeting"
            >
              <c-icon
                name="close"
                :size="['10px','17px']"
              />
            </c-button>
          </c-flex>
        </c-flex>
      </template>
      <template #body>
        <c-box>
          <c-box
            width="100%"
            max-height="600px"
            overflow="auto"
          >
            <c-box
              display="flex"
              justify-content="center"
              margin="24px 0 0 0"
            >
              <c-image
                v-if="popupDetail?.photoUrl"
                :height="['120px', '200px']"
                object-fit="cover"
                :src="popupDetail?.photoUrl"
                alt="image question"
              />
            </c-box>
          </c-box>

          <c-flex
            justify-content="center"
            :padding="['30px 40px 20px 40px','30px 100px 20px 100px']"
            text-align="center"
            :font-size="['14px', '18px']"
          >
            <c-box
              as="ol"
            >
              <c-box
                v-for="(item, index) in popupDetail.description?.split('\n')"
                :key="index"
                as="li"
                text-align="left"
              >
                {{ item }}
              </c-box>
            </c-box>
          </c-flex>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="80%"
            margin="auto"
            @click="onClickCekPanduanMakan"
          >
            Cek Panduan Makan
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-flex>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { CBox, CFlex, CGrid, CImage } from '@chakra-ui/vue'
import { mealTileIdToImageUrl, mealTileIdToLabel } from '@/constants/meal-time'
import {
  reqClient_mealPlans_read_put,
  reqMealPlansActiveShowPopup,
} from '@/requests/dietela-api/client/meal-plan'
import CardInfoThingsThatNeed from '@/components/meal-plan/_widgets/card-info-things-that-need.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'
import EmptyState from '@/views/client/meal-plan/widgets/empty-state.vue'
import { format24Time } from '@/utils/format-24-time'
import DownloadMealPlan from '@/views/client/meal-plan/widgets/download-meal-plan.vue'
import _ from 'lodash'

export default {
  components: {
    DownloadMealPlan,
    CImage, BaseButton, BaseModal,
    CardInfoThingsThatNeed,
    CBox,
    CFlex,
    CGrid,
    BreadcrumbPath,
    EmptyState,
  },
  props: {
    isHaveMealPlanMonths: {
      type: Boolean,
      default: false,
    },
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    activeProgram: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isOpenPopup: false,
      popupDetail: {
        photoUrl: '',
        description: '',
      },
    }
  },
  computed: {
    constants() {
      return {
        mealTileIdToLabel,
        mealTileIdToImageUrl,
      }
    },
    breadcrumbLabel() {
      return [
        {
          label: 'Dashboard',
          href: '/',
        },
        {
          label: 'Panduan Makan',
          href: '/meal-plan',
        },
        {
          label: 'Rencana Makan',
          isCurrent: true,
        },
      ]
    },
  },
  watch: {
    data: {
      handler(val) {
        if (Object.keys(val)?.length > 0) {
          this.getMealPlanPopup()
        }
      },
    },
  },
  methods: {
    format24Time,
    async handleClosePopupGreeting() {
      if (this.isOpenPopup === false) return
      this.isOpenPopup = false
      await reqClient_mealPlans_read_put(this.$store.getters.axios, {
        clientId: this.activeProgram?.clientId,
        programId: this.activeProgram?.id,
        month: this.$route.params.month,
      })
    },
    onClickItem(item) {
      if (item?.isSkipped) return
      this.$router.push({
        name: 'client.meal-plan.meal-plan-schedule.detail',
        params: {
          month: this.$route.params.month,
        },
        query: {
          ...this.$route.query,
          mealTime: item?.mealTime,
        },
      })
    },
    async getMealPlanPopup() {
      try {
        const res = await reqMealPlansActiveShowPopup(this.$store.getters.axios, {
          programId: this.activeProgram?.id || '',
        })

        if (_.isEmpty(res?.data?.data)) {
          return
        }
        this.popupDetail = res?.data?.data
        this.isOpenPopup = true
      } catch(err) {
        this.isOpenPopup = false
      }
    },
    onClickCekPanduanMakan() {
      this.handleClosePopupGreeting()
      if (this.$route.query.nextTour) {
        this.$router.push(this.$route.query.nextTour)
      }
    },
  },
}
</script>
